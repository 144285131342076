var AutomaticUpsells = React.createClass({
    propTypes: {
      media_url: React.PropTypes.string.isRequired,
      upsellsToInventory: React.PropTypes.object,
      selectedAutomaticUpsells: React.PropTypes.array,
      upsellCategories: React.PropTypes.object,
    },

    getInitialState: function () {
      var upsells = []
      var selectedUpsellIds = []
      var selectedUpsellMap = {}
      var upsellsToInventory = JSON.parse(JSON.stringify(this.props.upsellsToInventory))
      const selectedAutomaticUpsells = this.props.selectedAutomaticUpsells
      const allSelectedUpsells =
        selectedAutomaticUpsells && selectedAutomaticUpsells.length > 0 ? selectedAutomaticUpsells : [this.getFirstNewUpsell()]
      allSelectedUpsells.forEach(function (selectedUpsell) {
        selectedUpsellIds.push(selectedUpsell.id)
        upsells.push(selectedUpsell)
        selectedUpsellMap[selectedUpsell.id] = {
          quantityEqualType: selectedUpsell.quantity_equal_type,
          quantityNum: selectedUpsell.quantity_num,
        }
      })
      var totalOverallUpsells = 0
      var collapseCategories = []
      for (var upsellCategoryId in upsellsToInventory) {
        totalOverallUpsells += upsellsToInventory[upsellCategoryId].length
        var sameSelectedInventoryIds = []
        var upsellType = null
        var upsellNum = null
        upsellsToInventory[upsellCategoryId].forEach(function (upsell) {
          if (selectedUpsellIds.includes(upsell.id)) {
            const selectedUpsellObj = selectedUpsellMap[upsell.id]
            upsell.isSelected = true
            if (upsellType === null) {
              upsellType = selectedUpsellObj.quantityEqualType
              upsellNum = selectedUpsellObj.quantityNum
            }
            if (selectedUpsellObj.quantityEqualType === upsellType && selectedUpsellObj.quantityNum === upsellNum) {
              sameSelectedInventoryIds.push(upsell.id)
            }
          }
        })
        if (sameSelectedInventoryIds.length === upsellsToInventory[upsellCategoryId].length) {
          upsells = _.filter(upsells, function (upsell) {
            return !sameSelectedInventoryIds.includes(upsell.id)
          })
          upsells.push({
            id: upsellCategoryId,
            quantity_equal_type: upsellType,
            quantity_num: upsellNum,
          })
        }
      }

      return {
        upsells: upsells,
        upsellsToInventory: upsellsToInventory,
        totalOverallUpsells: totalOverallUpsells,
      }
    },

    changeSelectedUpsellHandler: function (idx, upsellId) {
      var upsells = this.state.upsells
      if (upsells[idx].id !== upsellId) {
        var upsellsToInventory = this.state.upsellsToInventory
        for (var upsellCategoryId in upsellsToInventory) {
          upsellsToInventory[upsellCategoryId].forEach(function (upsell) {
            if (upsellCategoryId === upsellId || upsell.id === upsellId) {
              upsell.isSelected = true
            } else if (upsell.id === upsells[idx].id || (upsells[idx].id in upsellsToInventory && upsell.categoryId === upsells[idx].id)) {
              upsell.isSelected = false
            }
          })
        }
        upsells[idx].id = upsellId
        this.setState({ upsells: upsells, upsellsToInventory: upsellsToInventory })
      }
    },

    changequantity_equal_typeHandler: function (idx, equalType) {
      var upsells = this.state.upsells
      if (upsells[idx].quantity_equal_type !== equalType) {
        upsells[idx].quantity_equal_type = equalType
        this.setState({ upsells: upsells })
      }
    },

    changequantity_numHandler: function (idx, equalNum) {
      var upsells = this.state.upsells
      if (upsells[idx].quantity_num !== equalNum) {
        upsells[idx].quantity_num = equalNum
        this.setState({ upsells: upsells })
      }
    },

    removeUpsellHandler: function (idx) {
      var upsells = this.state.upsells
      var upsellsToInventory = this.state.upsellsToInventory
      for (var upsellCategoryId in upsellsToInventory) {
        upsellsToInventory[upsellCategoryId].forEach(function (upsell) {
          if (upsell.id === upsells[idx].id) {
            upsell.isSelected = false
          }
        })
      }
      upsells.splice(idx, 1)
      this.setState({ upsells: upsells })
    },

    renderUpsell: function (selectedUpsell, idx) {
      return (
        <AutomaticUpsell
          media_url={this.props.media_url}
          selectedUpsell={selectedUpsell}
          upsellsToInventory={this.state.upsellsToInventory}
          upsellCategories={this.props.upsellCategories}
          changeSelectedUpsellHandler={this.changeSelectedUpsellHandler}
          changequantity_equal_typeHandler={this.changequantity_equal_typeHandler}
          changequantity_numHandler={this.changequantity_numHandler}
          removeUpsellHandler={this.removeUpsellHandler}
          idx={idx}
        />
      )
    },

    getFirstNewUpsell: function () {
      return {
        id: '',
        quantity_equal_type: 'PARTY_SIZE',
        quantity_num: '1',
      }
    },

    renderUpsells: function () {
      var renderedUpsells = []
      for (var i = 0; i < this.state.upsells.length; i++) {
        renderedUpsells.push(this.renderUpsell(this.state.upsells[i], i))
      }
      return renderedUpsells
    },

    addNewUpsell: function () {
      var upsells = this.state.upsells
      upsells.push(this.getFirstNewUpsell())
      this.setState({ upsells: upsells })
    },

    render: function () {
      return (
        <div>
          {this.renderUpsells()}
          <div className="inline" style={{ color: '#347baf', margin: '0 0 20px', cursor: 'pointer' }} onClick={this.addNewUpsell}>
            <i className="svr-icon- svr-icon--add"></i>
            <span style={{ margin: '0 0 0 3px' }}>Add another upgrade</span>
          </div>
        </div>
      )
    },
  }),
  AutomaticUpsell = React.createClass({
    propTypes: {
      media_url: React.PropTypes.string.isRequired,
      selectedUpsell: React.PropTypes.object.isRequired,
      upsellsToInventory: React.PropTypes.object,
      upsellCategories: React.PropTypes.object,
      idx: React.PropTypes.number,
    },

    radioCssStyle: function () {
      return {
        inputCss: { position: 'relative', left: 0, float: 'left', border: 0 },
        preLabelStyle: { paddingTop: '10px' },
        preInputStyle: { float: 'left', width: 'auto', height: 'auto', paddingTop: 10 },
        postLabelStyle: { margin: 0, width: 300, paddingTop: 10, paddingLeft: 20, fontWeight: 500 },
      }
    },

    changeSelectedUpsell: function (event) {
      this.props.changeSelectedUpsellHandler(this.props.idx, event.target.value)
    },

    changequantity_equal_type: function (event) {
      this.props.changequantity_equal_typeHandler(this.props.idx, event.target.value)
    },

    changequantity_num: function (event) {
      this.props.changequantity_numHandler(this.props.idx, event.target.value)
    },

    removeSelectedUpsell: function () {
      this.props.removeUpsellHandler(this.props.idx)
    },

    render: function () {
      const optgroupName = 'selected_upsell_' + this.props.idx
      const radioName = 'upsell_quantity_equal_type_' + this.props.idx
      const quantity_numName = 'upsell_quantity_num_' + this.props.idx
      return (
        <div style={{ margin: '0 0 20px' }}>
          <div className="inline">
            <modules.formElements.create
              type="fakeoptgroup"
              onChangeHandler={this.changeSelectedUpsell}
              value={this.props.selectedUpsell.id}
              name={optgroupName}
              groupIdToName={this.props.upsellCategories}
              options={this.props.upsellsToInventory}
              allowRepeats={false}
              media_url={this.props.media_url}
              preInputStyle={{ width: '14.5em' }}
              inputCss={{ padding: '6px 8px' }}
              defaultText="Please select an upgrade"
              style={{ backgroundColor: '#FFF', float: 'left', fontFamily: 'Roboto', clear: 'right', margin: '0' }}
            />
            {this.props.idx !== 0 ? (
              <span
                className="remover"
                onClick={this.removeSelectedUpsell}
                style={{ float: 'right', margin: '7px 10px', color: '#347baf', cursor: 'pointer' }}
              >
                <i className="svr-icon- svr-icon--clear"></i>
              </span>
            ) : undefined}
          </div>
          <div style={{ margin: '0 0 20px' }} className="inline">
            <modules.formElements.create
              type="radio"
              onClickHandler={this.changequantity_equal_type}
              value="PARTY_SIZE"
              defaultradio={this.props.selectedUpsell.quantity_equal_type === 'PARTY_SIZE'}
              name={radioName}
              id={radioName + '-0'}
              {...this.radioCssStyle()}
              label="Quantity equal to party size"
            />
            <modules.formElements.create
              type="radio"
              onClickHandler={this.changequantity_equal_type}
              value="SPECIFIC_NUMBER"
              defaultradio={this.props.selectedUpsell.quantity_equal_type === 'SPECIFIC_NUMBER'}
              name={radioName}
              id={radioName + '-1'}
              {...this.radioCssStyle()}
              label="Quantity equal to specific number"
            />
            {this.props.selectedUpsell.quantity_equal_type === 'SPECIFIC_NUMBER' ? (
              <modules.formElements.create
                type="number"
                value={this.props.selectedUpsell.quantity_num || 0}
                labelText=""
                onChangeHandler={this.changequantity_num}
                min="1"
                name={quantity_numName}
                style={{ margin: '10px 0 0 20px' }}
                preLabelStyle={{ fontSize: 11, fontWeight: 'normal' }}
                inputCss={{ width: 40, padding: '6px 8px' }}
              />
            ) : undefined}
          </div>
        </div>
      )
    },
  })
